import api from '@/services/api/api';

const defaultOptions = {
  staleTime: Infinity,
  refetchOnFocus: false,
  refetchOnReconnect: false,
};

export const usePopularArticles = ({ options, per_page = 30 }) => {
  const popularArticles = useQuery(
    ['popularArticles'],
    async () => await api.articles.getPopularArticles(per_page),
    { ...defaultOptions, ...options, select: ({ data }) => data?.data },
  );

  popularArticles.suspense();

  return {
    popularArticles,
  };
};
